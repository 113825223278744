<template>
  <div id="personageHuorHistogramId" style="width: 100%; height: 700px"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'PersonageHuorHistogram',
  props: {
    personageHuorList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {}
  },
  computed: {},

  watch: {
    personageHuorList: {
      deep: true,
      immediate: true,
      handler: function (list) {
        if (list.length > 0) {
          this.init(list)
        } else {
          this.init([])
        }
      },
    },
  },
  async created() {},
  mounted() {},
  methods: {
    // 项目收入统计
    init(arr = []) {
      function truncateToOneDecimal(num) {
        // 将数字转换为字符串，找到小数点的位置
        let str = num.toString()
        let dotIndex = str.indexOf('.')
        // 如果没有小数点，直接返回字符串
        if (dotIndex === -1) {
          return str
        }
        // 截取小数点后一位
        return str.slice(0, dotIndex + 2) // +2 是包括小数点和一位数字
      }
      this.$nextTick(() => {
        var chartDom = document.getElementById('personageHuorHistogramId')
        var myChart = echarts.init(chartDom)
        var option
        option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
            formatter: function (params) {
              const name = params[0].name // 获取 x 轴名称
              const value = Number(arr[params[0].dataIndex]).toLocaleString() // 获取小时
              return `${name}: ${value} 小时` // 返回 x 轴名称和小时
            },
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              data: [
                '1月',
                '2月',
                '3月',
                '4月',
                '5月',
                '6月',
                '7月',
                '8月',
                '9月',
                '10月',
                '11月',
                '12月',
              ],
              axisPointer: {
                type: 'shadow', // 鼠标悬停时显示的指示线类型
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
            },
          ],
          series: [
            {
              name: '',
              type: 'bar',
              barWidth: '60%',
              label: {
                show: true,
                position: 'top',
                formatter: function (params) {
                  const value = arr[params.dataIndex]
                  if (value >= 10000) {
                    return truncateToOneDecimal(value / 10000) + 'W'
                  } else if (value >= 1000) {
                    return truncateToOneDecimal(value / 1000) + 'K'
                  } else if (value < 0 && value <= -10000) {
                    return '-' + truncateToOneDecimal(-value / 10000) + 'W'
                  } else if (value < 0 && value <= -1000) {
                    return '-' + truncateToOneDecimal(-value / 1000) + 'K'
                  }
                  if (value == 0) {
                    return ''
                  }
                  return value + '小时'
                },
                fontSize: 14,
                fontWeight: 'bold',
              },
              data: arr,
            },
          ],
          color: ['#29aae3'], // 柱状图颜色
        }

        option && myChart.setOption(option)
        myChart.setOption(option, true) // 第二个参数设置为 true，表示完全更新配置项
        //监听窗口变化
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      })
    },
  },
}
</script>
<style scoped></style>
